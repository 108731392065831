<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" v-bind:style="{ backgroundImage: 'url(' + sideImg + ')' }">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img :src="logoImg" class="brand-logo-img">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            La aventura comienza aquí 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Cualquier duda que tengas, estamos para ayudarte
          </b-card-text>

          <!-- form -->
          <b-overlay
            :show="showOverlay"
            rounded="sm"
          >
          <ValidationObserver v-slot="{ handleSubmit}" ref="registerForm">
            <b-form @submit.prevent="handleSubmit(store)" class="auth-register-form mt-2">
              <b-alert
                variant="danger"
                show
                dismissible
                v-if="validationErrors && validationErrors.validationErrors.email.length"
              >
                <div class="alert-body">
                  <span><strong>{{validationErrors.validationErrors.email[0]}}</strong></span>
                </div>
              </b-alert>
              <b-alert
                variant="danger"
                  show
                  dismissible
                  v-if="validationErrors && validationErrors.message"
                >
                  <div class="alert-body">
                    <span><strong>{{validationErrors.message}}</strong></span>
                  </div>
              </b-alert>
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="register-username"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="user.name"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- confirm password -->
              
              <b-form-group
                label-for="register-confirm-password"
                label="Confirm Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.confirm_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
              >
                Registrarse
              </b-button>
            </b-form>
          </ValidationObserver>

          <p class="text-center mt-2">
            <span>¿Ya tienes una cuenta?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Iniciar sesión en su lugar</span>
            </b-link>
          </p>
          </b-overlay>
          <!-- divider -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>
<style scoped>
  .auth-inner {
    background-size: cover;
  }
  .brand-logo-img {
    max-width: 80px;
  }
  @media (max-width:768px){
    .auth-wrapper.auth-v2 .brand-logo {
    position: relative;
    top: 0;
    z-index: 1;
    text-align: center;
    margin: 0 auto !important;
    left:inherit;
    }
    .auth-wrapper.auth-v2 .auth-inner {
      background-image: none !important;
    }
  }
</style>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol,BCard, BLink, BAlert, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BOverlay
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/services/auth.service.js';

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCard,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    BOverlay,
    BAlert
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      
      sideImg: require('@/assets/images/pages/banner-1.jpg'),
      logoImg: require('@/assets/images/pages/anclademia-favi.png'),
      // validation
      required,
      email,
      user: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
      },
      showOverlay: false,
      errors: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    validationErrors(){
      //return this.errors;
      // if(this.errors.data && this.errors.data.message.email.length > 0) {
      //   console.log('error')
      //   console.log(this.errors.data.message.email)
      //   return this.errors.data.message.email[0];
      // }
      return this.errors.data
    },
  },
  methods: {
    store() {
      this.showOverlay = true;
          AuthService.registration(this.user).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Form Submitted',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push('/verify-email');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
              if(error.response.data && error.response.data.validationErrors.email.length > 0){
                //this.errors = error.response.data.validationErrors.email[0]
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.validationErrors.email[0],
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            }); 
    },
  }
}
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
